@import '_variables.less';

.eb-tree-select {
  display: block;
}

.eb-divider {
  margin: 0;
}

.eb-tree-select__skeleton {
  height: 302px;
  position: absolute;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: @Purple-900-o16;
  z-index: 1000;
}